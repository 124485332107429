import React from 'react';
import { signIn } from '../../utils';

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.signIn = signIn;
  }

  async componentDidMount() {
    const { authClient } = this.signIn;
    const session = await authClient.session.get();
    console.log('session.status', session.status);
    // Session exists, show logged in state.
    if (session.status === 'ACTIVE') {
      // clear parameters from browser window
      window.location.hash = '';
      console.log('Login -> componentDidMount -> window', window);
      // set username in state
      localStorage.setItem('isAuthenticated', 'true');
      console.log('returning');
      window.location.reload();
      return;
    }

    console.log('continue');
    this.signIn.remove();

    this.signIn.renderEl({ el: '#signIn' });
  }

  render() {
    return <div id="signIn" />;
  }
}
