import React from 'react';
import { navigate } from 'gatsby';
import Login from '../components/layout/login';
import { isAuthenticated } from '../utils';

const LoginPage = () => {
  if (typeof window === 'undefined') {
    console.log('LayoLoginPageut no window');
    return null;
  }

  if (isAuthenticated()) {
    navigate('/home');
    return <h1>Signing in...</h1>;
  }

  return <Login />;
};

export default LoginPage;
